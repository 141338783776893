.message {
    width: 100%;
    display: inline-block;
    color: #ffffff;
    padding: 2rem 2rem 1.9rem;
    margin: 1rem 0;
    border-radius: $border-radius;
    position: relative;
    text-align: left;
    box-sizing: border-box;

    &.has-input {
        margin: -1rem 0 1rem;

        &::before {
            content: "";
            font-size: 0;
            position: absolute;
            width: 2rem;
            height: 2rem;
            top: -0.8rem;
            transform: rotate(45deg);
            background-color: inherit;
            border-radius: $border-radius;
        }
    }
}

.message-success {
    background-color: $colour-success;
}

.message-error {
    background-color: $colour-danger;
}
