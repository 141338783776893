.divider {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    color: $colour-gray-3;

    &:before, &:after {
        content: "";
        background-color: $colour-gray-3;
        height: 0.2rem;
        width: 100%;
    }

    &:before {
        margin-right: 1rem;
    }

    &:after {
        margin-left: 1rem;
    }
}
