.quantity-toggle {
    display: flex;
    margin: 0;

    .product-item {
        flex-grow: 1;
        margin: 0 1rem;

        input {
            width: 100%;
        }
    }

    > .btn {
        width: 10rem;
        padding-left: 0;
        padding-right: 0;
    }
}
