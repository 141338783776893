.aspectratio {
  width: 100%;
  height: 0;
  position: relative;
}
.aspectratio[data-ratio="1:1"] {
  padding-top: 100%;
}
.aspectratio[data-ratio="4:3"] {
  padding-top: 75%;
}
.aspectratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
