.payment-method-container {
    .payment-method-input {
        height: 5rem;
        font-family: sans-serif;
        font-weight: 600;
    }
}

.payment-method {
    margin-bottom: 2rem;

    .payment-method-icon > * {
        max-width: 6rem;
    }
}
