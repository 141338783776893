.panel-cover {
    width: 100%;
    min-height: 100vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    padding: 8rem 5rem;

    @include media-breakpoint-up(sm) {
        .container {
            display: flex;
        }
    }

    h1 {
        font-size: 5rem;
    }
}

.panel-bg-bread {
    background-image: url('../../images/cover/bread.jpg');

    @include media-breakpoint-down(sm) {
        background-image: url('../../images/cover/bread-xs.jpg');
    }
}
