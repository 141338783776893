html { font-size: 62.5%; }

body {
	font-family: 'Work Sans', sans-serif;
	color: $colour-gray-1;
	background-color: $colour-background;
	margin: 0;
	font-size: 2rem;
	height: 100%;
	-webkit-user-select: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

html, body {
	overflow-x: hidden;
}

#app {
	position: relative;
}

a {
	text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p {
	margin: 0;
	padding: 0 0 2rem;
	line-height: calc(100% + 1rem);
	text-align: left;
	width: 100%;

	small {
		&.smaller {
			font-size: small;
		}
	}

	a {
		color: inherit;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

h1 {
	font-weight: 800;
	font-size: 8rem;
}

h2 {
	font-weight: 800;
	font-size: 6rem;
	color: $colour-gray-2;
}

h3 {
	font-weight: 800;
	font-size: 5rem;
	color: $colour-gray-3;
}

h4 {
	font-weight: 700;
	font-size: 4rem;
	color: $colour-gray-4;
}

h5 {
	font-weight: 700;
	font-size: 3rem;
	color: $colour-gray-5;
}

h6 {
	font-weight: 600;
	font-size: 2.4rem;
	color: $colour-gray-6;
}

p {
	line-height: calc(100% + 0.8rem);
	color: $colour-gray-1;
	font-weight: 400;
	font-size: 2rem;
	text-rendering: optimizeLegibility;
}

strong {
	font-weight: 600;
}

hr {
	height: 0.8rem;
	width: 100%;
	border: none;
	background-color: #dcdee2;
	margin: 0 0 2rem;
}

h1.header {
	padding: 2rem 0;
}

.float-left {
	float: left;
}
.float-right {
	float: right;
}

.hidden {
	display: none !important;
}

.clearfix:after {
	display: block;
	clear: both;
	content: "";
}

.no-padding {
	padding-bottom: 0 !important;
}

.no-margin {
	margin-bottom: 0 !important;
}

@include media-breakpoint-down(md) {
	html { font-size: 50%; }

	.text-sm-left {
		text-align: left;
	}
	.text-sm-right {
		text-align: right;
	}

	h1 {
		font-size: 5rem;
	}

	h2 {
		font-size: 4.5rem;
	}

	h3 {
		font-size: 4rem;
	}

	h4 {
		font-size: 3.5rem;
	}
}

.grecaptcha-badge {
	visibility: hidden;
}

@include media-breakpoint-down(sm) {
	.text-xs-left {
		text-align: left !important;
	}
	.text-xs-right {
		text-align: right !important;
	}
	.text-xs-center {
		text-align: center !important;
	}

	h1.header {
		padding-top: 3rem;
		padding-right: 3rem;
	}

	.container {
		padding: 0;

		& h1, & p {
			&:not(.card h1):not(.card p) {
				padding-left: 3rem;
				padding-right: 3rem;
				width: auto;
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	.container {
		max-width: 1920px;
	}
}
