.banner {
  position: relative;
  color: #222222;
  background-color: $white;
  text-align: center;
  padding: 2rem 0;
  font-family: 'Open Sans', sans-serif;

  &.banner-success {
    color: #ffffff;
    background-color: $colour-success;
  }

  &.banner-primary {
    color: #ffffff;
    background-color: $colour-primary;
  }

  .banner-content {
    padding: 0 6rem;
  }
  .banner-close {
    color: inherit;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
  }
}