.image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;

    > * {
        border-radius: $border-radius;
        overflow: hidden;
    }

    .image-grid-placeholder {
        border: 1px dashed rgba(0,0,0,0.1);
    }
}
