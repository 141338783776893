$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 2000px,
    xxl: 2100px
);

$colour-primary: #9B7363;
$colour-secondary: #BDC2B0;
$colour-success: #2d6858;
$colour-danger: #a9398d;
$colour-warning: #F5B44B;
$colour-default: #dcdee2;

$lf-card-padding-top: 5rem;
$lf-card-padding-sides: 5rem;

// Text colour and <h1>
$colour-gray-1: #303030;

// Title colours <h2> - <h6>
$colour-gray-2: #545659;
$colour-gray-3: #7b7d82;
$colour-gray-4: #abaeb5;
$colour-gray-5: #c3c6cc;
$colour-gray-6: #c3c6cc;

$colour-placeholder: #c3c6cc;

// Page background colour
$colour-background: #FAFAFA;

// <hr> colour
$colour-gray-7: #dcdee2;

// Border Radius for panels, buttons, etc.
$border-radius: 4px;

// Border radius for square badges
$border-radius-badge: 6px;
