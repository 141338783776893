.header {
    padding: 3rem 0 0;
}

.product-item {
    .product-img-wrapper img {
        transition: 0.2s;
    }
    .product-img-wrapper:hover img {
        transform: scale(1.1);
    }

    @include media-breakpoint-down(sm) {
        .product-img-wrapper {
            padding-top: 50%;
        }
    }
}
