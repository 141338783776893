.product-item {
    .product-img-wrapper {
        overflow: hidden;
        width: 100%;
        padding-top: 25%;
        position: relative;
        background-color: #ecedef;

        img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .product-img-upload {
            width: 15rem;
            height: 15rem;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.5);
            transition: 0.2s;

            &:hover {
                transform: translate(-50%, -50%) scale(1.2);
            }

            i {
                font-size: 4rem;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: rgba(0, 0, 0, 0.4);
            }
        }
    }
}

.product-card {
    padding: 6.5rem 0 3rem;
    transition: 0.2s;
    backface-visibility: hidden;
    background-color: transparent;

    .badge-wrapper {
        position: absolute;
        z-index: 1000;
        top: 0;
        right: 0;
        transform: translate(20%, -30%);

        .badge {
            padding: 0.2rem 1.2rem;
            font-size: 2.4rem;
            user-select: none;
        }
    }

    > .action-buttons {
        margin-top: 2rem;
    }

    .badge-discount {
        position: absolute;
        bottom: 1rem;
        left: 2rem;
    }

    .product-img-wrapper {
        margin: -6.5rem 0 2rem;
        overflow: hidden;
        width: 100%;
        padding-top: calc(60% + 10rem);
        position: relative;
        border-radius: $border-radius;

        img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.4s;
        }

        .action-buttons {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background-color: rgba(255, 255, 255, 0.8);
            padding: 4rem;
            opacity: 0;
            transition: 0.2s;

            > div {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }

            h6 {
                color: #7b7d82;
            }
        }
    }

    h2 {
        font-size: 2rem;
    }

    .badge [data-tooltip] {
        font-size: 1.4rem;
        max-width: 15rem;
    }

    .product-description {
        position: relative;
        height: 9rem;
        overflow: hidden;
        margin-bottom: 1rem;

        &:after {
            content: "";
            position: absolute;
            left: -50%;
            bottom: 0;
            height: 6rem;
            width: 300%;
            box-shadow: inset 0 -2.5rem 2.5rem 0 #ffffff;
        }
    }

     &:hover {
        transform: scale(1.03);
        cursor: pointer;

        .action-buttons {
            opacity: 1;
        }

        .product-img-wrapper img {
            transform: scale(1.1);
        }
    }
}
