.order-product {
    .order-product-img-wrapper {
        position: relative;

        > .badge-wrapper {
            position: absolute;
            display: flex;
            margin-left: -1rem;
            bottom: 1rem;
            align-items: center;
            gap: 1rem;
        }
    }

    .order-product-img {
        border-radius: 2px;
        overflow: hidden;
    }

    .order-product-content {
        margin: 1.6rem 0 2rem;
    }

    .card-loading-facade {
        position: absolute;
    }
}
