.alert {
  color: #ffffff;
  padding: 2rem 2rem 1.9rem;
  border-radius: $border-radius;
  text-align: left;
  margin-bottom: 2rem;

  &:not(.alert-notification) {
    font-size: 1.6rem;
    animation: hideAfterDelay 0.5s ease-out 19.5s /*forwards*/;
  }

  &.alert-notification {
    font-size: 2rem;
    z-index: 1000;
    transition: 0.5s;
    opacity: 1;

    // Define closed styles, alerts will be added with the 'open' class
    &:not(.alert-open) {
      opacity: 0;
    }
  }
}

.alert-primary {
  background-color: $colour-primary;
}

.alert-success {
  background-color: $colour-success;
}

.alert-danger {
  background-color: $colour-danger;
}

.alert-notifications {
  position: fixed;
  right: 0;
  top: 0;
  margin: 4rem;
  z-index: 1000;
}

@keyframes hideAfterDelay {
  0% {
    height: 7rem;
    opacity: 1;
  }
  100% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}
