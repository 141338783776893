@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

@import "variables";

@import "~bootstrap/scss/bootstrap-grid";

@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";

@import "base";

// Mixins
@import "mixins/logo";

// Utilities
@import "components/image";
@import "components/text";
@import "utils/sizeUtils";

// Components
@import "components/list";
@import "components/buttons";
@import "components/card";
@import "components/pagination";
@import "components/errorMessage";
@import "components/formControl";
@import "components/product";
@import "components/basket";
@import "components/tables";
@import "components/alert";
@import "components/banner";
@import "components/navbar";
@import "components/badge";
@import "components/footer";
@import "components/tooltip";
@import "components/aspectRatio";
@import "components/process";
@import "components/inputGroup";
@import "components/dayPicker";
@import "components/well";
@import "components/paymentMethod";
@import "components/timeline";
@import "components/modal";
@import "components/radioControl";
@import "components/orderProduct";
@import "components/imageGrid";
@import "components/divider";
@import "components/quantityToggle";
@import "components/gallery";
@import "components/panel";
@import "components/breadcrumb";

// Pages
@import "pages/login";
@import "pages/product";
@import "pages/products";
@import "pages/admin";
@import "pages/payment";
@import "pages/deliveryDetails";
@import "pages/user";
@import "pages/orderHistory";
@import "pages/home";

// Animations
@import "animations/pulse";
