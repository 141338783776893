/* .aw = absolute width */
.aw-100 {
    max-width: 100px;
}

.aw-200 {
    max-width: 200px;
}

.aw-300 {
    max-width: 300px;
}

.w-100 {
    width: 100%;
}
