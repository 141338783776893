[data-tooltip] {
  font-family: 'Open Sans', sans-serif;
  background-color: $colour-gray-3;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  max-width: 30rem;
  text-align: center;
  padding: 0.7rem 1rem 1rem;
  margin-top: 10px;
  border-radius: $border-radius;
  color: white;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    left: calc(50% - 0.8rem);
    top: -0.8rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0.8rem 0.9rem 0.8rem;
    border-color: transparent transparent $colour-gray-3 transparent;
  }

  &:after {
    content: "";
    position: absolute;
    left: calc(50% - 5.5rem);
    top: -2rem;
    width: 3rem;
    height: 0;
    border-style: solid;
    border-width: 0 4rem 2rem 4rem;
    border-color: transparent transparent transparent transparent;
  }

  &.tooltip-secondary {
    background-color: $colour-secondary;
    &:before {
      border-color: transparent transparent $colour-secondary transparent;
    }
  }

  &.tooltip-padded {
    padding: 1.8rem 2rem 2rem;
  }

  a {
    color: $white;
  }
}

[data-toggle=tooltip] {
  z-index: 2;
}

[data-toggle=tooltip] [data-tooltip] {
  display: none;
}

[data-tooltip]:hover, [data-toggle=tooltip]:hover [data-tooltip] {
  display: block;
}
