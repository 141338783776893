.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-primary {
  color: $colour-primary;
}

.text-success {
  color: $colour-success;
}

.text-danger {
  color: $colour-danger;
}

.text-strikethrough {
  text-decoration: line-through;
}

.text-link {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.text-bold {
  font-weight: bold !important;
}
