.basket-total {
    margin: 2rem 0;
}

.basket-item {
    transition: 0.2s;

    &:hover {
        transform: scale(1.05);
    }

    .badge-wrapper {
        z-index: 1000;
    }

    .basket-item-content {
        .title, .price {
            padding: 0;
            font-size: 2rem;
        }
    }

    .basket-item-img {
        overflow: hidden;
        width: 100%;
        padding-top: 40%;
        margin: -2rem -2rem 1.4rem;
        position: relative;
        border-radius: $border-radius $border-radius 0 0;
        box-sizing: border-box;

        img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
