// This forces siblings elements to match the width of
// the '.col-fit-target' element
.col-fit {
  display: inline-block;

  > *:not(.col-fit-target) {
    display: flex;

    > div {
      flex-grow: 1;
      width: 0;
    }
  }
}


.date-picker-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 2rem;
}