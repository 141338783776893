#login-card {
    max-width: 60rem;
    min-width: 40rem;
    position: relative;
    left: 50%;
    transform: translate( -50%);
    padding: 6.5rem 5rem;
    text-align: center;

    .close-button {
        position: absolute;
        left: 0;
        top: -5rem;
    }

    .login-logo {
        @include logo-font(4rem);

        display: inline-block;
        margin-bottom: 4rem;

        img {
            width: 100%;
            max-width: 30rem;
        }
    }

    @include media-breakpoint-down(md) {
        .captcha-container {
            margin-left: -$lf-card-padding-sides;
            margin-right: -$lf-card-padding-sides;

            > .g-recaptcha > * {
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;

        .card.card-padded {
            margin-left: 0;
            margin-right: 0;
        }

        .close-button {
            left: 3rem;
        }
    }
}
