// Basic Bootstrap table
.table {
  width: 100%;
  border-collapse:separate;
  border-spacing: 0 1rem;

  td, th {
    text-align: left;
    position: relative;
  }

  thead th {
    vertical-align: bottom;
    padding: 2rem 1rem;
    text-align: left;
    font-size: 2.4rem;
    border-bottom: 0.5rem solid #dcdee2;
  }

  tbody {
    td, th {
      vertical-align: middle;
      padding: 0 1rem 1rem;
      border-bottom: 0.5rem solid #edeef0;
      border-radius: $border-radius;
    }
  }

  thead, tbody {
    th, td {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }

  tr:last-child td {
    border-bottom: none;
  }

  tr.strike {
    td, th {
      color: $colour-placeholder;

      &:before {
        content: " ";
        position: absolute;
        top: calc(50% - 0.3rem);
        left: 0;
        border-bottom: $border-radius solid #dcdee2;
        width: 100%;
      }
    }
  }

  tr.no-border {
    td, th {
      border-bottom: 0;
    }
  }

  tr.no-padding {
    td, th {
      padding: 0;
    }
  }

  //tr.border-top {
  //  td, th {
  //    border-top: 0.5rem solid #edeef0;
  //    padding: 1rem 0;
  //  }
  //}

  .cell-success {
    background-color: $colour-success;
    color: $white;
  }

  .cell-warning {
    background-color: $colour-warning;
    color: $white;
  }

  .cell-danger {
    background-color: $colour-danger;
    color: $white;
  }
}

@include media-breakpoint-only(xs) {
  .table.table-responsive {
    /* Force table to not be like tables anymore */
    &, thead, tbody, th, td, tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tbody {
      tr {
        padding: 3rem 0 2rem;

        &:not(:last-child) {
          border-bottom: 0.5rem solid #edeef0;
        }

        &:last-child {
          padding-bottom: 0;
        }

        td, th {
          border: none;
          position: relative;
          padding-left: 50% !important;
          text-align: left !important;

          &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(50% - #{$lf-card-padding-sides});
            white-space: nowrap;
            font-weight: bold;
            content: attr(data-title)
          }
        }
      }
    }
  }
}
