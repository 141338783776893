.well {
  background-color: #fafafa;
  padding: 2rem 2rem 1.5rem;
  border-radius: $border-radius;
  margin-bottom: 2rem;
  position: relative;
  margin-left: $grid-gutter-width / -2;
  margin-right: $grid-gutter-width / -2;
  display: block;

  &.well-filled {
    &.well-default {
      box-shadow: inset 0 0 0 3px $colour-default;
    }

    &.well-primary {
      background-color: $colour-primary;
    }
  }

  &.well-padded {
    padding: $lf-card-padding-top $lf-card-padding-sides;
  }

  &.well-col-adjust {
    margin-left: 0;
    margin-right: 0;
  }

  &.well-interactive {
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }
  }

  &:not(.well-filled) {
    &.well-default {
      box-shadow: inset 0 0 0 3px $colour-default;
    }

    &.well-success {
      box-shadow: inset 0 0 0 3px $colour-success;
    }
  }

  > .badge-wrapper {
    position: absolute;
    top: -1.5rem;
    right: -2rem;
  }
}
