.modal-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10001;
    backdrop-filter: blur(0.5rem);

    .modal {
        max-width: 80rem;
        width: 80vw;
        height: auto;
        position:absolute;
        left: 50%;
        top: 20vh;
        transform: translateX(-50%);
        transition: 0.5s;

        .modal-btn-actions {
            margin-bottom: -3rem;
        }

        @for $i from 0 through 3 {
            &.modal-depth-#{$i + 1} {
                z-index: -$i + 3;
                transform: translateX(-50%) translateY($i * -30px) scale((100 + ($i * 5)) / 100);
                filter: blur($i * 3px) brightness(1 / (($i + 1) - 1 / ($i + 1)));
                opacity: 1 / (($i + 1) - 1 / ($i + 1));
            }
        }
    }
}
