.card {
	background-color: #ffffff;
	border-radius: $border-radius;
	position: relative;
	margin: 0 0 2rem;

	&.card-padded {
		padding: $lf-card-padding-top $lf-card-padding-sides;
	}

	h1 {
		font-size: 3rem;
	}

	h2 {
		font-size: 2.5rem;
	}

	h3 {
		margin-top: 0.8rem;
		font-size: 2.5rem;
	}

	h4 {
		font-size: 2rem;
	}

	h5 {
		font-size: 2rem;
	}

	h6 {
		font-size: 2rem;
	}

	.card-stat {
		font-weight: 800;
		font-size: 6rem;
		color: $colour-gray-2;
		padding: 0;
	}

	.card-metric {
		font-weight: 600;
		font-size: 2rem;
		color: $colour-placeholder;
		display: block;
	}

	&.card-success, &.card-warning, &.card-danger {
		> * {
			color: $white;
		}
	}

	&.card-success {
		background-color: $colour-success;
	}

	&.card-warning {
		background-color: $colour-warning;
	}

	&.card-danger {
		background-color: $colour-danger;
	}
}

.card-display {
	text-align: center;

	h1, h2, h3, h4, h5, h6, p {
		text-align: center;
	}

	> h1 {
		font-size: 3.5rem;
	}

	> h2, h3 {
		font-size: 3rem;
	}

	> h4, > h5, > h6 {
		font-size: 2.5rem;
	}

	> i {
		color: $colour-gray-3;
		font-size: 5rem;
		margin-bottom: 3rem;
	}
}

.card-loading-facade {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.6);
	top: 0;
	left: 0;
	z-index: 800;

	i {
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 6rem;
		animation:spin 1.2s linear infinite;
		color: $colour-gray-1;
	}
}

.container .card-loading-facade {
	position: fixed;
}

@keyframes spin {
	0% {transform: translate(-50%, -50%) rotate(0deg); }
	100% { transform: translate(-50%, -50%) rotate(359deg); }
}
