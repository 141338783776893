.btn {
	font-family: 'Work Sans', sans-serif;
	color: #ffffff;
	border: none;
	font-size: 2.4rem;
	font-weight: 700;
	background-color: $colour-default;
	padding: 1.8rem 4rem;
	outline: none;
	box-shadow: inset 0 0 0 0 rgba(0,0,0,0.07);
	transition: 0.2s;
	text-decoration: none;
	vertical-align: middle;
	text-align: center;
	border-radius: $border-radius;
	-webkit-appearance: none;
	display: inline-block;
	margin: 0 0 2rem;
	cursor: pointer;
	position: relative;
	box-sizing: border-box;

	&:hover, &:focus {
		box-shadow: inset 0 -7rem 0 0 rgba(0,0,0,0.07);
	}

	&:active {
		transform: scale(1.05);
		box-shadow: inset 0 -7rem 0 0 rgba(0,0,0,0.17);
	}

	&:disabled, &.disabled {
		opacity: 0.5;
		pointer-events: none;

		&:hover {
			box-shadow: none;
		}
	}

	&.btn-icon {
		padding: 0;
		width: 5rem;
		min-width: 5rem;
		height: 5rem;
		line-height: 5.8rem;
	}
}

.btn-primary {
	background-color: $colour-primary;
}

.btn-danger {
	background-color: $colour-danger;
}

.btn-warning {
	background-color: $colour-warning;
}

.btn-success {
	background-color: $colour-success;
}

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
	display: inline-block;
	box-shadow: none;
	background-color: transparent;
	color: #abaeb5;
	border: none;
	outline: none;
}
.btn-link:hover {
	text-decoration: underline;
}
.btn-link:active {
	box-shadow: none;
	background-color: transparent;
	color: #7b7d82;
}

.btn-link-inline {
	display: inline;
	padding: 0;
	margin: 0;
	border: none;
	background: none;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	font-family: inherit;
	vertical-align: inherit;

	&:hover {
		box-shadow: none;
		text-decoration: underline;
	}
}

.btn-sm {
	font-size: 1.6rem;
	padding: 1.3rem 3rem 1.2rem;

	&.btn-icon {
		padding: 0;
		width: 4.4rem;
		height: 4.4rem;
		line-height: 4.4rem;
	}
}

.btn-icon-round {
	padding: 0;
	width: 4rem;
	height: 4rem;
	line-height: 4.3rem;
	background-color: transparent;
	color: #abaeb5;
	border-radius: 50%;
}

@include media-breakpoint-down(md) {
	.btn:not(.btn-icon-round):not(.btn-link-inline) {
		width: 100%;
	}
}
