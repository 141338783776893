.gallery-display {
    position: relative;

    > .next, > .previous {
        position: absolute;
        background: transparent;
        top: 0;
        height: 100%;
        width: 15%;

        > * {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 3rem;
            transition: 0.2s;
        }

        &:hover > * {
            transform: scale(1.1) translate(-50%, -50%);
        }
    }

    > .next {
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
        right: 0;
        border-radius: 0 $border-radius $border-radius 0;
    }

    > .previous {
        background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
        left: 0;
        border-radius: $border-radius 0 0 $border-radius;
    }

    .gallery-toolbar {
        bottom: 0;
        padding: 2rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

.gallery-modal {
    height: 0;

    .img-cover {
        width: 100%;
    }
}

.gallery {
    margin: 0 0 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;

    .gallery-scroll-wrapper {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        margin: -1rem;
    }

    .gallery-thumbnail-wrapper {
        display: inline-flex;
        cursor: pointer;
        padding: 1rem;
        height: 100%;

        > .gallery-thumbnail {
            min-height: 10rem;
            height: 100%;
            width: 10rem;
            display: inline-block;
            transition: 0.2s;
            position: relative;

            &:not(.selected) {
                opacity: 0.5;
            }

            &:hover {
                transform: scale(1.1);
            }

            > * {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
