.breadcrumb {
    list-style-type: none;
    padding: 0;

    @include media-breakpoint-down(sm) {
        padding: 0 2rem;
    }

    .breadcrumb-item {
        display: inline-block;
        color: $colour-gray-3;

        > * {
            padding: 0.5rem 1rem;
            display: inline-block;
        }

        a {
            color: $colour-gray-2;
            border-radius: 2px;
            box-shadow: inset 0 0 0 0 rgba(0,0,0,0.07);
            transition: 0.2s;

            &:hover, &:focus {
                box-shadow: inset 0 -7rem 0 0 rgba(0,0,0,0.07);
            }

            &:active {
                box-shadow: inset 0 -7rem 0 0 rgba(0,0,0,0.17);
            }
        }

        &:not(:last-child):after {
            content: '\f0da';
            font-family: "Font Awesome 5 Free", sans-serif;
            font-weight: 900;
            color: $colour-gray-4;
            padding: 1rem;
        }
    }
}
