.form-control {
	font-family: 'Work Sans', sans-serif;
	margin: 0 0 2rem;
	color: #545659;
	border: none;
	font-size: 2.4rem;
	font-weight: 700;
	padding: 0.8rem 0 0.7rem;
	background-color: transparent;
	outline: none;
	box-shadow: inset 0 -1.5rem 0 -1rem rgba(0,0,0,0.05);
	transition: 0.2s;
	vertical-align: middle;
	border-radius: $border-radius;
	width: 100%;
	-webkit-appearance: none;
}
.form-control:hover {
	box-shadow: inset 0 -2rem 0 -1rem rgba(0,0,0,0.05);
}
.form-control:focus {
	box-shadow: inset 0 -1.8rem 0 -1rem rgba(0,0,0,0.07);
	background-color: transparent;
}
.form-control::placeholder {
	color: $colour-placeholder;
}

.form-control[type=number] {
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

textarea.form-control {
	width: 100%;
	min-width: 100%;
	max-width: 100%;
}

.form-control-discrete {
	box-shadow: none;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	line-height: inherit;
}

table .form-control-discrete {
	margin-bottom: 0;
	margin-top: -1rem;
}

.form-control-discrete:hover {
	box-shadow: inset 0 0 0 1rem rgba(0,0,0,0.05);
}

.form-control-discrete:focus {
	box-shadow: inset 0 0 0 8rem rgba(0,0,0,0.01);
}

.form-control-distinct {
	background-color: $colour-background;
	box-shadow: 0 0.3rem 0.3rem 0 rgba(0,0,0,0.05);

	&:hover {
		box-shadow: 0 0.3rem 0.5rem 0 rgba(0,0,0,0.05);
	}

	&:focus {
		background-color: $colour-background;
		box-shadow: 0 0.3rem 0.4rem 0 rgba(0,0,0,0.07);
	}
}

.select-wrapper {
	position: relative;


	&:after {
		content: "\f0d7";
		font-family: "Font Awesome 5 Free", sans-serif;
		font-weight: 900;
		position: absolute;
		top: 1.6rem;
		right: 1rem;
		pointer-events: none;
		color: $colour-gray-2;
	}

	> select option[value=""] {
		color: $colour-placeholder;
	}
}
