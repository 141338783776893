.product-search {
  font-size: 3rem;
  padding: 0;
  margin: 2rem 0 -5rem;
  font-weight: 700;

  &, &:hover, &:focus {
    box-shadow: none;
  }
}

.product-cards-wrapper {
  --bs-gutter-x: 4rem;

  @for $i from 1 through 20 {
    > *:nth-child(#{$i}) {
      z-index: #{20 - $i};
    }
  }
}
