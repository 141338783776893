.badge {
  color: #ffffff;
  background-color: $colour-default;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 2rem;
  padding: 0.2rem 1.2rem;
  border-radius: 30px;
  vertical-align: middle;
  position: relative;
}

.badge-default {
  color: #303030;
  background-color: $colour-default;
}

.badge-primary {
  background-color: $colour-primary;
}

.badge-success {
  background-color: $colour-success;
}

.badge-danger {
  background-color: $colour-danger;
}

.badge-warning {
  background-color: $colour-warning;
}

.badge + .badge {
  margin-left: 0.5rem !important;
}

.badge-square {
  border-radius: $border-radius-badge;
}

.badge-discount {
  border-radius: $border-radius;
  border: 0.3rem solid $colour-default;
  color: $colour-default;
  background-color: white;
  padding: 0.6rem 1rem 0.5rem;
  display: inline-flex;
  align-items: center;

  &.badge-discount-danger {
    border-color: $colour-danger;
    color: $colour-danger;
  }

  &.badge-discount-success {
    border-color: $colour-success;
    color: $colour-success;
  }

  .badge-discount-offer {
    line-height: 2.6rem;
    text-align: left;
  }

  .badge-discount-criteria {
    font-size: 1.2rem;
    margin-left: 1.2rem;
    line-height: 1.4rem;
    max-width: 10rem;
  }
}

.badge-pricetag {
  border-radius: 0 $border-radius-badge $border-radius-badge 0;
  margin-left: 2.4rem;
  padding-left: 0.1rem;
  height: 3.1rem;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    width: 2.5rem;
    height: 100%;
    top: 0;
    left: -2.3rem;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 31"><path fill="%23dcdee2" d="M16.3,0a8,8,0,0,0-5.7,2.3L1.8,11.3a6,6,0,0,0,0,8.5l8.9,8.9A8,8,0,0,0,16.3,31H24V0H16.3ZM12,19.5a4,4,0,1,1,4-4A4,4,0,0,1,12,19.5Z" transform="translate(0)"/></svg>');
  }

  &.badge-danger:before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 31"><path fill="%23ef5662" d="M16.3,0a8,8,0,0,0-5.7,2.3L1.8,11.3a6,6,0,0,0,0,8.5l8.9,8.9A8,8,0,0,0,16.3,31H24V0H16.3ZM12,19.5a4,4,0,1,1,4-4A4,4,0,0,1,12,19.5Z" transform="translate(0)"/></svg>');
  }

  @include media-breakpoint-down(md) {
    padding: 0.2rem 1.2rem;
    margin-left: 0;
    border-radius: $border-radius-badge;

    &:before {
      display: none;
    }
  }
}
