.animate-pulse {
    animation: pulse .3s ease-in-out normal;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    95% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(1);
    }
}