nav {
	width: 100%;
}

.pagination {
	list-style-type: none;
	padding: 0;
	text-align: center;
}

.pagination .page-item {
	display: inline-block;
	transition: 0.2s;
	border-radius: $border-radius;
	border: none;
	margin: 0 0.4rem;
}

.pagination .page-item .page-link {
	display: block;
	text-align: center;
	font-size: 2.4rem;
	text-decoration: none;
	padding: 0.7rem 2rem;
	color: #545659;
	border: none;
	box-shadow: inset 0 0 0 0 rgba(0,0,0,0.07);
	cursor: pointer;
}

.pagination .page-item:hover, .btn:focus {
	box-shadow: inset 0 -7rem 0 0 rgba(0,0,0,0.07);
}
.pagination .page-item:active {
	box-shadow: inset 0 -7rem 0 0 rgba(0,0,0,0.17);
}

.pagination .page-item.active {
	box-shadow: inset 0 -1.8rem 0 10rem rgba(0,0,0,0.07);
}
