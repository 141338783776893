.footer {
  width: 100%;
  padding: 8rem 0 6rem;
  background-color: #f4f5f6;
  margin-top: 8rem;
  color: #abaeb5;

  @include media-breakpoint-down(sm) {
    padding-bottom: 8rem;
  }

  .footer-logo {
    @include logo-font(5rem);
    img {
      width: 100%;
      max-width: 30rem;
    }

    @include media-breakpoint-down(md) {
      display: inline-block;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .footer-social-bar {
    font-size: 3rem;
    padding-top: 1rem;

    .footer-social-link {
      color: inherit;

      &.instagram:hover {
        color: #fc297a;
      }

      &.etsy:hover {
        color: #f1641e;
      }
    }
  }

  .footer-copyright {
    text-align: right;

    @include media-breakpoint-down(md) {
      margin-top: 2rem;
      text-align: center;
    }
  }

  .footer-language {
    .footer-language-title {
      font-size: 3rem;
      line-height: 1rem;
      padding-top: 1rem;
    }
  }
}
