.home {
  h1, h2, h3, h4, h5, h6 {
    color: #838383;
    font-weight: normal;
  }

  h1 {
    font-size: 4rem;
  }

  .home-card {
    background-color: #FAF6F4;
    padding: $lf-card-padding-top $lf-card-padding-sides;
    border-radius: 0.6rem;

    h2, p {
      color: #838383;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    h2 {
      font-size: 3.6rem;
    }
  }

  .section-container {
    width: 80%;

    @include media-breakpoint-up(lg) {
      max-width: 1400px;
    }
    @include media-breakpoint-down(md) {
      width: unset;
    }
  }

  .hc-section {
    position: relative;
    margin: 3rem auto;
    padding: 10rem 0;

    @include media-breakpoint-down(lg) {
      padding: 2rem 0;
    }

    .hc-section-img {
      position: absolute;
      height: 100%;
      width: 70%;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 0.6rem;
    }

    .hc-section-img-sm {
      padding: 0;
      margin: 3rem 0;

      img {
        max-height: 100%;
        max-width: 100%;
        border-radius: 0.6rem;
        z-index: 0;
      }
    }

    .home-card {
      z-index: 1;
    }
  }

  .hc-section-1 {
    width: 90%;
    @include media-breakpoint-down(lg) {
      width: unset;
    }

    .hc-section-img {
      background-position: left center;
      background-image: url('../../images/home/section-1.png');
    }
  }

  .hc-section-2 {
    width: 80%;
    @include media-breakpoint-down(lg) {
      width: unset;
    }

    .hc-section-img {
      right: 0;
      background-position: right center;
      background-image: url('../../images/home/section-2.png');
    }
  }

  .home-products {
    padding: 10rem 0;
  }

  .home-etsy {
    background-color: #F7EFEA;
    padding: 10rem 0;

    h2 {
      font-weight: normal;
      font-size: 3.6rem;
      padding-top: 1rem;
      margin-bottom: 3rem;
    }
  }

  .home-etsy-star-seller-logo {
    max-width: 30rem;
  }

  .home-testimonial {
    font-size: 2rem;
    color: #898989;
    font-weight: normal;
    position: relative;
    padding: 5rem;
    z-index: 1;
    width: calc(100% - 10rem);
    margin: 0 0 3rem;

    &:before, &:after {
      color: #E5D6CD;
      position: absolute;
      font-size: 30rem;
      z-index: -1;
    }

    &:before {
      content: "“";
      top: 10rem;
      left: 0;
    }

    &:after {
      content: "”";
      bottom: 0;
      right: 0;
    }

    .stars {
      display: block;
      color: #F2B614;
      font-size: 2.6rem;
      margin-top: 1rem;
    }
  }
}
