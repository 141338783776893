.admin {
  .content-card {
    padding: 6.5rem 5rem;
    margin: 2rem 0;

    form {
      width: 100%;
    }
  }

  .clear-control {
    box-shadow: none;
  }
}
