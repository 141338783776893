.timeline {
    display: grid;
    grid-template-columns: [tag] auto [content] 1fr;
    grid-template-rows: auto [last-line];
    grid-auto-flow: row;
    column-gap: 1rem;
    margin: 2rem 0;

    .timeline-event {
        margin: 1rem 0 1rem 4rem;
        position: relative;

        &:before {
            content: '';
            height: calc(100% + 2rem);
            border: none;
            border-left: solid 0.8rem $colour-gray-7;
            position: absolute;
            top: -1rem;
            left: -5.6rem;
            z-index: -1;
        }

        &:after {
            content: '';
            width: 3rem;
            border-bottom: 0.8rem dotted $colour-gray-7;
            position: absolute;
            left: -3.5rem;
            top: 50%;
            transform: translateY(-50%);
        }

        &.timeline-event-pending {
            > h2 {
                color: $colour-primary;
            }

            &:before {
                border-left-style: dotted;
            }
        }

        .timeline-img img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: $border-radius;
        }
    }

    .timeline-tag {
        justify-self: end;
        align-self: center;
        position: relative;
        margin-right: 2rem;
        color: $colour-gray-1;
        font-weight: 600;

        &:before {
            width: 1.5rem;
            height: 1.5rem;
            content: '';
            background-color: $colour-gray-3;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            box-shadow: 0 0 0 0.5rem $colour-gray-7;
            right: -3.3rem;
        }

        &.timeline-tag-pending {
            color: $colour-primary;

            &:before {
                background-color: $colour-gray-7;
                box-shadow: 0 0 0 0.5rem $colour-primary;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .timeline-tag:nth-child(2n + 1) {
            width: 0;
            color: transparent;
        }

        .timeline-img {
            margin-bottom: 2rem;
        }
    }
}
