@include media-breakpoint-up(md) {
  .navigation-wrapper {
    max-width: 80%;
    margin: auto;
  }
}

.navigation-wrapper-img {
  position: absolute;
  left: 0;
  width: 100%;

  > img {
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}

.navigation-wrapper-logo {
  max-width: 30rem;
  width: 80%;
  margin: auto;
  padding-top: 3rem;

  > img {
    width: 100%;
  }
}

.nav-search {
  flex-grow: 1;
  position: relative;
  margin: auto;
  margin-bottom: 3rem;
  max-width: 80%;

  input {
    font-size: 2rem;
    background-color: #ffffff;
    padding: 1rem 0 1rem 2rem;
    margin: 0;
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }

  &::after {
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    content: "\f002";
    color: $colour-gray-5;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.6rem;
    font-size: 2rem;
    pointer-events: none;
  }
}

.navbar {
  .nav-link {
    transition: 0.1s;

    &:hover {
      color: #545659;
      transform: scale(1.1);
    }
  }
}

.nav-main {
  text-align: center;
  padding: 0 0 1rem;
  margin-bottom: 2rem;
  margin-top: 5rem;
  font-size: 3rem;
  font-weight: 700;
  border-bottom: 0.2rem solid $colour-gray-5;
  box-shadow: 0 2rem 2rem 5rem $colour-background;
  background-color: $colour-background;

  @include media-breakpoint-down(sm) {
    border-bottom: none;
  }

  .navbar-nav {
    display: flex;
    margin: auto;
    line-height: 100%;

    .nav-item:not(:first-child) {
      margin-left: 4rem;
    }

    a.nav-link, div.nav-link, a.navbar-brand {
      vertical-align: middle;
      color: $colour-gray-5;
      text-decoration: none;
      position: relative;
    }

    .navbar-brand {
      font-size: 3rem;
      line-height: calc(100% + 0.8rem);
      flex-grow: 1;

      .logo {
        text-align: left;

        &:hover {
          color: #545659;
        }
      }
    }

    .nav-link {
      .badge {
        position: relative;
        top: -2rem;
        left: -1rem;
        display: inline-block;
      }
    }

    [data-tooltip] {
      a.nav-link {
        font-family: 'Open Sans', sans-serif;
        color: $white;
        display: inline-block;
        cursor: pointer;

        &:hover {
          transform: scale(1.1, 1.1);
        }
      }
    }

    .nav-notification-wrapper {
      .badge {
        position: absolute;
        left: auto;
        right: -2.5rem;
      }
    }
  }

  &.navbar-xs {
    padding: 3rem 3rem 0;
    margin: 0;
    width: calc(100% - 6rem);

    .navbar-brand {
      width: 50% !important;
    }

    .nav-search {
      pointer-events: none;

      input {
        background-color: #F2F2F2;
        width: 100%;
        padding-left: 2rem;
        box-shadow: 0 0 0rem 2.5rem $colour-background;
        text-align: left;
        pointer-events: all;

        &::placeholder {
          color: $colour-gray-4;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  &:not(.navbar-xs) {
    @include media-breakpoint-down(sm) {
      position: fixed;
      z-index: 10000;
      left: 0;
      bottom: 0;
      background-color: #F8F8F8;
      line-height: 18px;
      padding: 0.5rem 0 0;
      height: 7.5rem;
      font-size: 1.8rem;
      box-shadow: 0 -1px 0 0 rgb(0, 0, 0, 0.1);
      margin-bottom: 0;

      .navbar-nav {
        .nav-item {
          padding: 1rem;
          color: #8E8E93;
          text-decoration: none;
          flex-grow: 1;
          margin: 0;

          &.active {
            color: $colour-primary;
          }

          .nav-notification-wrapper {
            position: relative;
            display: inline-block;

            .badge {
              position: absolute;
              left: 1.4rem;
              top: -1.6rem;
              padding: 0.4rem 1rem;
              font-size: 1.4rem;
            }
          }
        }

        span {
          display: block;
          font-size: 1.6rem;
          font-weight: normal;
          padding-top: 0.5rem;
        }
      }
    }
  }
}

.nav-product-categories {
  position: relative;
  margin: 0 0 0 -3rem;
  font-size: 2rem;
  max-width: calc(100% - 12rem);

  .navbar-nav {
    padding: 1rem 3rem 0.6rem;
    display: flex;
    white-space: nowrap;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .nav-link {
      padding-right: 4rem;
      display: inline-block;
      color: $colour-gray-5;
      font-weight: 700;

      &.active {
        color: $colour-primary;
      }
    }
  }

  &::after {
    content: '';
    width: 100%;
    height: 4rem;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    box-shadow: inset -6rem 0 2rem -4rem $colour-background, inset 6rem 0 2rem -4rem $colour-background;
  }

  @include media-breakpoint-down(sm) {
    padding: 1rem 0 1rem 3rem;
    background-color: transparent;
    font-size: 1.6rem;
    width: 100%;
    border-bottom: 1px solid #E3E3E3;

    .nav-search {
      margin: 0rem 2rem 1rem;
    }

    &::after {
      margin-top: 1rem;
      box-shadow: inset -6rem 0 2rem -4rem $colour-background, inset 6rem 0 2rem -4rem $colour-background;
    }
  }
}
