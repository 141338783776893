.process {
  display: grid;

  @media screen and (min-width: 801px) {
    grid-auto-columns: 1fr;
    .cell {
      grid-row: 1;
    }
  }

  .cell {
    float: left;
    padding: 2rem;
    position: relative;
  }

  .cell:not(:last-child) .aspectratio:after {
    content: "\f061";
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    position: absolute;
    width: 1em;
    height: 1em;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    right: -1.2em;
    font-size: 3rem;
  }

  .cell > .node-item {
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 0px transparent;

    transition: 0.5s;
    color: #222222;

    &:hover {
      box-shadow: 0px 0px 0px 5px rgba(0,0,0,0.1);
      > * {
        transform: scale(1.2);
      }
    }
  }

  .cell > .node-text {
    display: block;

    h3, p {
      font-size: 20px;
      font-family: 'Work Sans', sans-serif;
      text-align: center;
      color: #222222;
    }

    h3 {
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    p {
      margin: 0;
      font-size: 16px;
    }
  }

  .cell > .node-item > div {
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      font-size: 3.5rem;
      text-align: center;
      padding-bottom: 0.4rem;
    }
  }
}


@media screen and (max-width: 800px) {
  .process {
    flex-direction: column;
    max-width: 300px;
    margin: auto;

    .cell:not(:last-child) .aspectratio:after {
      display: none;
    }
  }
}
