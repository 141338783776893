/* DayPicker styles */
.DayPicker {
  display: inline-block;
  font-size: 2rem;
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 1rem;

  *:focus {
    outline: none;
  }

  &-wrapper {
    position: relative;

    flex-direction: row;
    padding-bottom: 1rem;

    user-select: none;
  }

  &-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-Month {
    display: table;
    margin-top: 1rem;
    border-spacing: 0.4rem;
    user-select: none;
  }

  &-NavButton {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    left: auto;

    display: inline-block;
    width: 3rem;
    height: 3rem;
    color: #8B9898;
    cursor: pointer;
    text-align: center;
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;

    &:hover {
      opacity: 0.8;
    }

    &--prev {
      padding-right: 5rem;

      &:before {
        content: "\f053";
      }
    }

    &--next:before {
      content: "\f054";
    }

    &--interactionDisabled {
      display: none;
    }
  }

  &-Caption {
    display: table-caption;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
    text-align: left;

    > div {
      font-weight: 400;
      font-size: 2rem;
    }
  }

  &-Weekdays {
    display: table-header-group;
    margin-top: 1rem;
  }

  &-WeekdaysRow {
    display: table-row;
  }

  &-Weekday {
    display: table-cell;
    padding: 0.5rem;
    color: #8B9898;
    text-align: center;
    font-size: 1.4rem;

    abbr[title] {
      border-bottom: none;
      text-decoration: none;
    }
  }

  &-Body {
    display: table-row-group;
  }

  &-Week {
    display: table-row;
    margin-bottom: 3px;
  }

  &-Day {
    display: table-cell;
    padding: 0.5rem 0.7rem;
    border-radius: $border-radius;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    transition: 0.1s;

    &:hover {
      transform: scale(1.1);
    }

    &--today {
      color: $colour-primary;
      font-weight: 700;
    }

    &--outside {
      color: $colour-primary;
      cursor: default;
    }

    &--disabled {
      color: #DCE0E0;
      cursor: default;
    }

    &--selected:not(&--disabled):not(&--outside):not(&--not-selected) {
      position: relative;

      background-color: $colour-primary;
      color: #ffffff;
    }
  }

  &-WeekNumber {
    display: table-cell;
    padding: 0.5rem;
    min-width: 1rem;
    border-right: 1px solid #EAECEC;
    color: #8B9898;
    vertical-align: middle;
    text-align: right;
    font-size: 2rem;
    cursor: pointer;
  }

  &-Footer {
    padding-top: 0.5rem;
  }

  .DayPicker-Day--not-selected.DayPicker-Day--selected:not(.DayPicker-Day--outside) {
    background-color: $colour-default;
  }
}
