.card-user-settings {
    transition: 0.2s;

    > div {
        top: 50%;
        transform: translateY(-50%);
        height: auto;
        padding: 2rem;
        width: 100%;
        box-sizing: border-box;
    }

    h1, h2, p {
        transition: 0.4s;
        text-align: center;
    }

    h1 {
        font-size: 4rem;
        color: #303030;
    }

    h2 {
        padding-bottom: 0;
    }

    p {
        color: #c3c6cc;
        font-size: 1.8rem;
    }

    &:hover {
        transform: scale(1.03);

        > div h1 {
            transform: scale(1.3);
        }
    }
}
