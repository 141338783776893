.radio-control {
    input[type='radio'] {
        display: none;

        + label {
            position: relative;
            top: 50%;
            box-shadow: inset 0px 0px 0px 3px $colour-gray-6;
            transform: translateY(-50%);
            display: block;
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            background-color: white;
            transition: 0.2s;
            margin: 0;
        }

        &:checked + label {
            background-color: $colour-success;
            box-shadow: inset 0px 0px 0px 3px $colour-success;

            &:before {
                font-family: "Font Awesome 5 Free", sans-serif;
                font-weight: 900;
                content: "\f00c";
                color: white;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.option-well {
    .option-content {
        padding-top: 1rem;

        h4 {
            padding: 0 0 0.6rem
        }
    }

    .option-well-facade {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        top: 0;
        left: 0;
    }

    &:hover {
        .radio-control input[type='radio'] + label {
            box-shadow: inset 0px 0px 0px 3px $colour-success;
        }
    }
}
